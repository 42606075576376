@tailwind base;

/* Write your own custom base styles here */

/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Write you own custom component styles here */

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

/* Local fonts */
@font-face {
	font-family: 'theinhardt';
	src: url('../public/theinhardt/theinhardt-regular-webfont.ttf') format('truetype'),
		url('../public/theinhardt/Theinhardt-Regular.woff') format('woff'),
		url('../public/theinhardt/Theinhardt-Regular.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'theinhardt';
	src: url('../public/theinhardt/theinhardt-light-webfont.ttf') format('truetype'),
		url('../public/theinhardt/Theinhardt-Light.woff') format('woff'),
		url('../public/theinhardt/Theinhardt-Light.woff2') format('woff2');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'theinhardt';
	src: url('../public/theinhardt/theinhardt-bold-webfont.ttf') format('truetype'),
		url('../public/theinhardt/Theinhardt-Bold.woff') format('woff'),
		url('../public/theinhardt/Theinhardt-Bold.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'inter';
	src: url('../public/inter/Inter-Regular.ttf') format('truetype'),
		url('../public/inter/Inter-Regular.woff') format('woff'),
		url('../public/inter/Inter-Regular.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'inter';
	src: url('../public/inter/Inter-Light.ttf') format('truetype'),
		url('../public/inter/Inter-Light.woff') format('woff'), url('../public/inter/Inter-Light.woff2') format('woff2');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'inter';
	src: url('../public/inter/Inter-SemiBold.ttf') format('truetype'),
		url('../public/inter/Inter-SemiBold.woff') format('woff'),
		url('../public/inter/Inter-SemiBold.woff2') format('woff2');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'inter';
	src: url('../public/inter/Inter-Bold.ttf') format('truetype'), url('../public/inter/Inter-Bold.woff') format('woff'),
		url('../public/inter/Inter-Bold.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'space-mono';
	src: url('../public/Space_Mono/SpaceMono-Bold.ttf') format('truetype'),
		url('../public/Space_Mono/SpaceMono-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'space-mono';
	src: url('../public/Space_Mono/SpaceMono-Regular.ttf') format('truetype'),
		url('../public/Space_Mono/SpaceMono-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

/* Your own custom utilities */

.has-text-align-left {
	@apply text-left;
}

.has-text-align-center {
	@apply text-center;
}

.has-text-align-right {
	@apply text-right;
}

.has-large-font-size {
	@apply text-4xl;
}

.wp-video {
	max-width: 100% !important;
}

.alignfull {
	@apply w-screen relative;

	left: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	max-width: 100vw;
	right: 50%;
}

.wp-block-image img {
	@apply max-w-full mb-6;
}
.wp-block-image.aligncenter {
	@apply text-center;
}
.wp-block-image.alignfull img,
.wp-block-image.alignwide img {
	@apply w-full;
}
.wp-block-image .alignleft,
.wp-block-image .alignright,
.wp-block-image .aligncenter,
.wp-block-image.is-resized {
	@apply table ml-0 mr-0;
}
.wp-block-image .alignleft > figcaption,
.wp-block-image .alignright > figcaption,
.wp-block-image .aligncenter > figcaption,
.wp-block-image.is-resized > figcaption {
	@apply table-caption;

	caption-side: bottom;
}

.wp-block-image .alignleft {
	@apply float-left mr-4;
}
.wp-block-image .alignright {
	@apply float-right ml-4;
}
.wp-block-image .aligncenter {
	@apply m-auto;
}

.wp-block-button a,
.wp-block-file a.wp-block-file__button {
	@apply bg-blue-500 text-white no-underline py-2 px-4;
}

.wp-block-button a:hover,
.wp-block-file a.wp-block-file__button:hover {
	@apply bg-blue-400 cursor-pointer;
}

.wp-block-file a:first-of-type {
	@apply mr-4;
}

.wp-block-cover {
	@apply flex flex-wrap justify-center items-center bg-cover bg-center overflow-hidden;

	min-height: 430px;
}

.wp-block-verse {
	@apply font-sans;
}

.wp-block-media-text {
	@apply grid grid-cols-2 gap-4;
}

/* Button */
.kkd-button {
	@apply text-base font-semibold rounded-md font-inter leading-none cursor-pointer border-2 border-transparent uppercase tracking-widest !no-underline;
}
.kkd-button--primary {
	@apply text-white bg-red-500;
}
.kkd-button--primary:hover {
	@apply bg-red-600 transition-colors duration-300;
}
.kkd-button--primary:hover > svg {
	@apply fill-white transition-colors duration-300;
}
.kkd-button--secondary {
	@apply text-black-500 bg-transparent border-2 border-black-500;
}
.kkd-button--secondary:hover {
	@apply bg-black-500 text-white border-black-500 transition-colors duration-300;
}
.kkd-button--secondary:hover > svg {
	@apply fill-white transition-colors duration-300;
}
.kkd-button--medium {
	@apply text-base leading-none py-3.5 px-4;
}
.kkd-button--small {
	@apply text-sm leading-none py-2 px-3.5;
}
.kkd-button--inverted {
	@apply text-red-500 bg-white;
}
.kkd-button--inverted:hover {
	@apply bg-gray-100 transition-colors duration-300;
}

/* Used for overlay over images */
.kkd-gradient-dark-overlay {
	@apply absolute top-0 left-0 h-full w-3/4 bg-gradient-to-r from-kkd-dark z-20 pointer-events-none;
}

.kkd-gradient-dark-overlay-tl {
	@apply p-0 m-0 absolute bottom-0 left-0 h-full w-full opacity-60 bg-gradient-to-t from-black-500;
}

.kkd-gradient-red-overlay {
	@apply absolute top-0 left-0 h-full w-full bg-gradient-to-t from-kkd-red z-20 pointer-events-none;
}

.kkd-gradient-red-overlay-t {
	@apply p-0 m-0 absolute bottom-0 left-0 h-full w-full bg-gradient-to-t from-red-500 to-transparent opacity-80;
}

.kkd-gradient-red-overlay-y {
	@apply p-0 m-0 absolute bottom-0 left-0 w-full h-1/2 bg-gradient-to-t from-red-500 to-transparent opacity-80;
}

.kkd-gradient-red-overlay-vertical {
	@apply p-0 m-0 absolute bottom-0 left-0 bg-gradient-to-r from-red-500 to-transparent;
}

.kkd-gradient-red-overlay-diagonal {
	overflow: hidden;
}
.kkd-gradient-red-overlay-diagonal::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100px;
	background: linear-gradient(rgba(226, 1, 61, 1) 0%, rgba(226, 1, 61, 1) 28%, rgba(0, 0, 0, 0) 100%);
	transform: translate(0, 0) rotate(-90deg);
}
/* Additional Overrides Swiper Component */
.swiper {
	display: flex !important;
	flex-direction: column-reverse;
}

/* Additional styles for Tables */
.legend-blue {
	box-shadow: inset 4px 0px 0px 0px rgba(77, 138, 252, 1);
	@apply w-[4px] h-[32px] block m-0 p-0 rounded-sm;
}
.legend-yellow {
	box-shadow: inset 4px 0px 0px 0px rgba(226, 165, 39, 1);
	@apply w-[4px] h-[32px] block m-0 p-0 rounded-sm;
}
.legend-blue-lg {
	box-shadow: inset 8px 0px 0px 0px rgba(77, 138, 252, 1);
	@apply w-[8px] h-[32px] block mx-3 p-0 rounded;
}
.legend-yellow-lg {
	box-shadow: inset 8px 0px 0px 0px rgba(226, 165, 39, 1);
	@apply w-[8px] h-[32px] block mx-3 p-0 rounded;
}
.table-snug .legend-blue {
	@apply h-[22px];
}
.table-snug .legend-yellow {
	@apply h-[22px];
}
.table-snug td,
.table-snug th {
	@apply p-2 leading-6;
}
.table-snug td {
	@apply text-sm xl:text-base;
}
.table-snug th {
	@apply text-[10px] md:text-xs xl:text-sm;
}
.table-medium td,
.table-medium th {
	@apply px-3 py-4 text-sm lg:text-base leading-6;
}
.table-medium th {
	@apply text-xs lg:text-sm xl:text-base;
}
.table-large td,
.table-large th {
	@apply px-3 py-4 text-base lg:text-lg leading-6;
}

.result-W {
	@apply inline-flex items-center justify-center rounded-sm bg-green-400 w-6 h-6 text-xs font-medium text-green-800 mx-[2px];
}
.result-D,
.result-G {
	@apply inline-flex items-center justify-center rounded-sm bg-gray-300 w-6 h-6 text-xs font-medium text-gray-800 mx-[2px];
}
.result-L,
.result-V {
	@apply inline-flex items-center justify-center rounded-sm bg-red-500 w-6 h-6 text-xs font-medium text-red-800 mx-[2px];
}

.table-snug .result-W {
	@apply inline-flex items-center justify-center rounded-sm bg-green-400 w-4 h-4 text-xs font-medium text-green-800 mx-[1px];
}
.table-snug .result-D,
.table-snug .result-G {
	@apply inline-flex items-center justify-center rounded-sm bg-gray-300 w-4 h-4 text-xs font-medium text-gray-800 mx-[1px];
}
.table-snug .result-L,
.table-snug .result-V {
	@apply inline-flex items-center justify-center rounded-sm bg-red-500 w-4 h-4 text-xs font-medium text-red-800 mx-[1px];
}

/* Card styles component */
.card {
	@apply flex-col;
}
.card-small,
.card-medium {
	@apply flex-row lg:flex-col;
}
.card-small .card-title {
	@apply mb-0 p-3 lg:p-0 lg:mb-1 inline-block break-words font-theinhardt font-bold text-base lg:text-sm;
}
.card-small .card-date {
	@apply mb-0 text-sm;
}
.card-small .card-content {
	@apply px-1 py-2;
}

.card-medium .card-title {
	@apply mb-0 p-3 lg:p-0 lg:mb-1 inline-block break-words font-theinhardt font-bold text-base lg:text-2xl;
}
.card-medium .card-date {
	@apply mb-0;
}
.card-medium .card-content {
	@apply px-1 py-2 lg:px-2 lg:py-4;
}

/* Tab styles component */
.tab-sm {
	@apply mx-0 py-4 lg:py-6 px-4 lg:px-6 text-sm lg:text-base font-inter font-bold;
}
.tab-md {
	@apply mx-0 lg:mx-4 mt-4 py-4 lg:py-6 px-4 lg:px-8 text-base lg:text-lg font-inter font-bold;
}
.tab-lg {
	@apply mx-0 lg:mx-4 mt-4 py-4 lg:py-6 px-4 lg:px-8 text-lg lg:text-2xl font-inter font-bold;
}

/* Logo slider */
.partners-slider {
	@apply mb-3.5;
}

.partners-slider .swiper-slide {
	@apply flex self-center;
}

/* Countdown */
.espn-wrapper,
.espn-countdown {
	@apply flex flex-col items-center justify-center text-center text-white uppercase py-14;
}

.espn-wrapper .espn-logo {
	@apply w-3/5 h-auto;
}

.espn-countdown {
	@apply bg-gradient-to-br via-transparent from-red-500 border-t border-t-red-500;
}

.espn-countdown .number {
	@apply h-20 w-16 xl:h-24 bg-black-500 flex justify-center items-center text-white rounded text-3xl mb-2;
}

/* Slider */
.front-page-swiper {
	@apply w-full h-80 md:h-96 lg:h-120 xl:h-168 bg-kkd-dark relative overflow-hidden z-10;
}

.front-page-swiper .swiper-preview {
	@apply w-64 xl:w-80 h-32 xl:h-40 bg-kkd-dark rounded overflow-hidden;
	@apply hidden md:flex absolute top-1/2 right-20 -translate-y-1/2 z-30;
	@apply justify-start items-end;
}

.front-page-swiper .swiper-preview .top-container,
.front-page-swiper .swiper-preview .to-action {
	@apply hidden;
}

.front-page-swiper .skip-to-preview {
	@apply w-16 h-32 xl:h-40 top-0 bg-kkd-red rounded-l hover:opacity-80 transition-opacity;
	@apply hidden md:flex absolute top-1/2 right-0 -translate-y-1/2 z-30;
	@apply justify-center items-center cursor-pointer;
}

.front-page-swiper .swiper-progress-bar {
	@apply w-36 h-2 bg-white rounded-md overflow-hidden;
	@apply absolute bottom-4 right-5 lg:bottom-6 lg:right-8 z-30;
}

.front-page-swiper .swiper-progress-bar .progress {
	@apply h-full w-0 bg-kkd-dark;
}

.front-page-swiper .swiper-slide {
	@apply flex justify-start items-center relative;
}

.front-page-swiper .swiper-slide img,
.front-page-swiper .swiper-preview img {
	@apply absolute block w-full h-full object-cover;
}

.front-page-swiper .swiper-preview img {
	@apply opacity-30 hover:opacity-60 transition-opacity;
}

.front-page-swiper .swiper-slide .slide-content,
.front-page-swiper .swiper-preview .slide-content {
	@apply relative z-30 p-4 text-lg font-medium pointer-events-none;
}

.front-page-swiper .swiper-slide .slide-content {
	@apply hidden;
}

.front-page-swiper .swiper-content {
	@apply absolute flex flex-col justify-center top-0 left-0 w-full h-full z-20 px-4 gap-5 lg:gap-8 md:px-8 lg:px-16 pointer-events-none;
	@apply md:max-w-[calc(100%-20rem)] xl:max-w-[980px];
}

.front-page-swiper .swiper-content .category,
.front-page-swiper .swiper-content .to-action div {
	@apply font-bold uppercase !text-lg lg:!text-xl;
}

.front-page-swiper .swiper-content .text-white {
	@apply text-3xl font-bold xl:text-6xl;
}

.rounds-table {
	@apply bg-gradient-to-r from-kkd-red border-separate;
}

.match-row {
	@apply bg-kkd-dark hover:bg-transparent transition hover:cursor-pointer hover:-my-px;
}

.match-row td {
	@apply text-center py-3.5 border-y border-[#3C384B];
}

.match-row td img {
	@apply inline-block align-middle;
}

.match-row:hover td {
	@apply border-y border-red-500 !text-white;
}

/* Video swiper */
.video-swiper img {
	@apply block w-full h-full object-cover;
}

.video-swiper .swiper-button-prev,
.video-swiper .swiper-button-next {
	@apply text-white;
}

.scaling-iframe {
	@apply w-full h-screen border-none;
}

.scaling-wp-iframe-speler-week {
	@apply w-full h-screen min-h-[2200px] sm:min-h-[1100px]	md:min-h-[1024px] lg:min-h-[1150px] border-none	overflow-hidden;
}

.scaling-wp-iframe {
	@apply w-full border-none h-full aspect-[3/4] overflow-hidden;
}

.scaling-poll-iframe {
	@apply w-full border-none h-full aspect-[3/4] sm:aspect-video overflow-hidden;
}

.iframe-wrapper {
	@apply h-full;
}

.youtube-video iframe,
.is-provider-youtube iframe {
	@apply h-full w-full aspect-video mb-4;
}

.wp-block-columns {
	display: flex;
	margin: 20px 0;
}

.wp-block-column {
	flex: 1;
	margin: 0 20px;
}

@media (max-width: 764px) {
	.wp-block-columns.has-3-columns {
		flex-flow: row wrap;
	}

	.has-3-columns .wp-block-column:first-child {
		flex-basis: 100%;
	}
}

@media (max-width: 478px) {
	.wp-block-columns.has-3-columns {
		display: block;
	}

	.wp-block-column {
		margin: 20px 0;
	}
}

/* tmp fix */
.hc-icon-button:hover svg {
	fill: #564c6b;
}

/* Additional styles for Footer */
.footer-sponsors .naamgevend {
	order: 0;
}
.footer-sponsors .responsible {
	order: 1;
}
.footer-sponsors .maatschappelijk {
	order: 2;
}
.footer-sponsors .maatschappelijk img {
	opacity: 0.7;
}
.footer-sponsors .maatschappelijk img:hover {
	opacity: 0.5;
}
.footer-sponsors .official {
	order: 3;
}
